.sidebar {
  background-color: rgb(234, 236, 238);
  bottom: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) -1px 0px 0px 0px inset;
  display: block;
  flex-basis: 30%;
  /*width: 16.666667%;*/
  width: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 80px;
  position: fixed;
  top: 0px;
  z-index: 0;
  background-color: #eaecee;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  width: 7px;
  background-color: #eaecee;
}

.sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #6d87ad;
}

.sidebar:hover .sidebar::-webkit-scrollbar,
.sidebar:hover .sidebar::-webkit-scrollbar-thumb {
  display: contents;
}

.sidebarTitle {
  text-transform: uppercase;
  font-size: 1.1rem;
  color: #6c757d;
  font-weight: 600;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.sidebarList {
  padding: 0.5rem 1rem;
  color: #317b9e;
  display: block;
}

.sidebar ul {
  list-style: none;
  padding-left: 0;
}
