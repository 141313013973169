/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;



html, .App {
  width: 100%;
}

body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

main {
  width: 100%;
  margin: 0px !important;
  padding-left: 1.5% !important;
  padding-right: 1.5% !important;
  padding-top: 4.3rem;
  padding-bottom: 2rem;
}

strong {
  font-weight: 600 !important;
}

.container {
  max-width: none !important;
  overflow-wrap: break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h3 {
  font-size: 1.5em !important;
  margin-bottom: 0.75em !important;
}
a {
  color: #34588d !important;
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
  font-weight: 600;
}

hr {
  margin-top: 0 !important;
}

textarea {
  text-align: justify;
  white-space: normal;
}

img {
  max-width: 100%;
  height: auto;
}


.table th {
  border: 1px solid #dee2e6;
}
.table-hover tbody tr:hover {
  cursor: inherit !important;
}

/* BUTTONS (Override Bootstrap) */
.btn {
  border-radius: 0 !important;
  padding-right: 13px !important;
  /* padding: 8px !important;*/
  padding-left: 13px !important;
}

.btn-sm {
  padding: 0.25rem 13px !important;
  font-size: 1rem !important;
}

.btn-primary {
  color: #fff;
  background-color: #34588d !important;
  border-color: #34588d !important;
}

.btn-secondary {
  color: #fff;
  background-color: #a41e34 !important;
  border-color: #a41e34 !important;
}

.btn-light {
  background-color: #dedede !important;
  border-color: #dedede !important;
}

.select {
  width: 100%;
}

/* LOGIN FORM */
.login {
  background: #456288;
  background: -webkit-linear-gradient(right, #456288, #516f96);
  background: -moz-linear-gradient(right, #456288, #516f96);
  background: -o-linear-gradient(right, #456288, #516f96);
  background: linear-gradient(to left, #456288, #516f96);
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .container {
  width:40%;
  background-color: white;
  padding: 2%;
  text-align: center;
}
@media only screen and (max-width : 901px) {
  .login .container {
    width:80%!important;
  }
}
.input-group-text{
  min-width: 115px;
}
/* FORM CUSTOM CHECKBOX SEMANAL */
.checkbox-custom {
  display: none;
}
.checkbox-custom-label {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}
.checkbox-custom + .checkbox-custom-label:before {
  background: #fff;
  border: 2px solid #ddd;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-right: 10px;
}
.checkbox-custom:checked + .checkbox-custom-label:after {
  text-align: center;
  position: absolute;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: white;
  background-color: #34588d;
  line-height: 1.8rem;
  border-width: 0 3px 3px 0;
  top: 0px;
  left: 0px;
}
.checkbox-custom.dilluns + .checkbox-custom-label:before,
.checkbox-custom.dilluns:checked + .checkbox-custom-label:after {
  content: "DL";
}

.checkbox-custom.dimarts + .checkbox-custom-label:before,
.checkbox-custom.dimarts:checked + .checkbox-custom-label:after {
  content: "DM";
}

.checkbox-custom.dimecres + .checkbox-custom-label:before,
.checkbox-custom.dimecres:checked + .checkbox-custom-label:after {
  content: "DC";
}

.checkbox-custom.dijous + .checkbox-custom-label:before,
.checkbox-custom.dijous:checked + .checkbox-custom-label:after {
  content: "DJ";
}

.checkbox-custom.divendres + .checkbox-custom-label:before,
.checkbox-custom.divendres:checked + .checkbox-custom-label:after {
  content: "DV";
}

.checkbox-custom.dissabte + .checkbox-custom-label:before,
.checkbox-custom.dissabte:checked + .checkbox-custom-label:after {
  content: "DS";
}

.checkbox-custom.diumenge + .checkbox-custom-label:before,
.checkbox-custom.diumenge:checked + .checkbox-custom-label:after {
  content: "DG";
}

/* TABLES */
.table thead th,
.modal-header {
  color: white !important;
  background-color: #34588d !important;
  border: none !important;
}

.table td,
.table th {
  vertical-align: middle !important;
  font-size: 14px !important;
  padding: 0.25rem 0.5rem !important;
}

.table-hover tbody tr:hover {
  cursor: pointer;
}

/* INPUTS FORMS */
.input-group-text {
  padding: 0rem 0.75rem !important;
}

.input-group {
  flex-wrap: inherit !important;
}

.input-group .select div:first-child,
.input-group .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* BADGES */
.badge {
  font-weight: normal !important;
  color: white !important;
}
.badge-primary {
  background-color: #92aac4 !important;
}
.badge-warning {
  background-color: #f9d379 !important;
}
.badge-danger {
  background-color: #e5838d !important;
}
.badge-light {
  color: inherit !important;
  border: 1px solid #e6e6e6;
}

/* CKEDITOR 5 */
.ck-editor__editable_inline {
  min-height: 250px;
}

.ck-content .image {
  margin: 0.5rem 0 !important;
}

/* FILTERS */
.save-filters {
  position: absolute;
  top: 7px;
  right: 22px;
  float: right;
  font-size: 20px !important;
  cursor: pointer;
}

.save-filters.fa-save {
  right: 10%;
}
.save-filters.fa-trash {
  right: 2%;
}

/* BUTTONS */
.icon-edit {
  position: absolute;
  font-size: 20px !important;
  right: 35px;
  cursor: pointer;
}

/* TOAST */
.react-toast-notifications__container {
  top: 75px !important;
}

/* ICONS */
.icon-ticket {
  width: 55px;
  float: right;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
  right: 0;
  margin-right: 25px;
  cursor: pointer;
}
.icon-ticket img {
  width: 15px;
  float: left;
  margin-left: 10px;
}

.alert-info {
  color: #34588d !important;
  background-color: #f8f9fa !important;
  border-color: #cfdaea !important;
}

/* CHECKBOX STYLING */
.check-list {
  padding: 0;
  -ms-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -o-transform: scale(1.3);
  padding: 10px;
  padding-left: 1.2rem;
  text-align: center;
  padding-right: 0;
}

/* PAGINATION */
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  justify-content: center;
}
.pagination > .active > a {
  background-color: #34588d;
  border-color: #34588d;
  color: #fff !important;
}
.pagination > li > a {
  border: 1px solid #34588d;
  border-left: none;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > li:first-child > a {
  border-left: 1px solid #34588d;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #34588d;
  border-color: #34588d;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #34588d;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

/* FITXERS ADJUNTS */
.adjunts-container {
  margin-bottom: 0.25rem;
  padding: 0.3rem 0.25rem;
  background-color: rgba(0, 0, 0, 0.03);
}
.adjunts-container img {
  width: 20px;
  margin-right: 5px;
}
.adjunts-container a {
  font-size: 0.85rem;
}

/* TABLES LINKS */

.table a {
  font-weight: normal;
  color: inherit !important;
}
.table a:hover {
  font-weight: normal;
  color: inherit !important;
}

.dropdown-menu.show {
  position: fixed !important;
}

.table td,
.table th {
  position: relative !important;
  border-right: 0 !important;
  border-left: 0 !important;
}

/* INLINE SELECT */
.inline-select__value-container {
  /*display: none !important;*/
}

.inline-select__control {
  background: transparent !important;
  border-color: transparent !important;
  cursor: pointer !important;
}
.inline-select__control:hover {
  border-color: transparent !important;
  border: none !important;
}

.inline-select__indicator {
  padding: 0 !important;
  cursor: pointer;
}

.inline-select__indicator-separator,
.inline-select__single-value {
  display: none !important;
}

.inline-select-container,
.inline-select-container-top,
.inline-select-container-bottom {
  position: absolute !important;
  top: 7px;
  right: 5px;
  width: 100% !important;
  cursor: pointer !important;
}

.inline-select-container-top {
  top: -6px;
}

.inline-select-container-bottom {
  top: 17px;
}

:focus {
  outline: none !important;
}

.inline-select__control input {
  color: transparent !important;
}
.dropdown-item.active,
.dropdown-item:active {
  color: white !important;
  background-color: #34588d !important;
}

/* TEXTAREA COMPATIBILITY - MOZILLA FIREFOX */
textarea {
  white-space: break-spaces;
}

.form-group {
  margin-bottom: 0.5rem !important;
}
.totop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  cursor: pointer;
}

.gestion-btn{
  margin-top: 0.5rem;
  padding: 0.4rem;
}