.pageHeader {
  border-bottom: 1px solid #dee2e6;
  margin-top: 1.4rem;
  margin-bottom: 1rem;
  overflow: hidden;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  z-index: 1010;

  width: 100%;
}
.title {
  color: #34588d;
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 1.75rem;
  float: left;
}

.titleButton {
  margin-bottom: 10px;
  float: right;
}
