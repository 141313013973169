.header {
  background-color: rgb(248, 249, 250);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
  color: rgb(33, 37, 41);

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  z-index: 40;
  position: fixed;
  width: 100%;
}

.logo {
  margin-left: 3rem;
}

.userTitle {
  color: #34588d;
  font-weight: 600;
  font-size: 28px;
  margin: 20px;
  margin-top: 15px;
  text-align: center;
}

.actions {
  /*display: block;*/
  /*margin-right: 6rem;*/
  margin-right: 15px;
}

/*.langSelector {
  display: inline-block;
  margin-left: 2rem;
}*/
